<template>
  <div class="wrap">
    <div class="page-wrap">
      <div class="page-title">
        <img src="../../assets/activity/logo.png" alt="" class="logo" />
        <div class="marketing">{{ ecology.marketing }}</div>
        <div class="Genesis">———{{ ecology.Genesis }}</div>
      </div>
    </div>
    <div class="shade">
      <div class="yinxiao-box">
        <div class="edu">{{ ecology.total }}</div>
        <div class="money">
          {{ donatedInfo.donatedCount * donatedInfo.amount || 0}}MEB
        </div>
      </div>
      <div class="yinxiao-box">
        <div class="edu">{{ ecology.purpose }}</div>
        <div class="money">{{ ecology.mark }}</div>
      </div>
      <div class="yinxiao-box">
        <div class="edu">{{ecology.Number}}</div>
        <div class="money">{{ this.$t('ecology.Donation', { nunber: donatedInfo.amount || 0 }) }}</div>
      </div>
      <div class="yinxiao-box">
        <div class="edu">{{ ecology.equity }}</div>
        <div class="money">{{ ecology.dividend }}</div>
      </div>
      <div class="yinxiao-box">
        <div class="edu">{{ ecology.Current }}</div>
        <div class="money">
          {{ this.$t("ecology.have", { value: donatedInfo.donatedCount || 0 })
          }}{{
            this.$t("ecology.remaining", {
              value: (donatedInfo.donateLimit - donatedInfo.donatedCount) || 0,
            })
          }}
        </div>
      </div>
      <div class="yinxiao-box">
        <div class="edu">{{ ecology.quantity }}</div>
        <div class="money">{{ donatedInfo.totalCount }}</div>
      </div>
      <div class="yinxiao-box">
        <div class="edu">{{ ecology.address }}</div>
        <div class="address" @click="handleCopy(lightingAddress)">
          <div class="title">{{ $gbUtils.formatAddress(lightingAddress) }}</div>
          <img src="@/assets/copy.png" class="copy-img" />
        </div>
      </div>
      <van-button
        class="btn-div"
        :class="{'disabled': donatedInfo.donateLimit === donatedInfo.donatedCount || flag}"
        @click="approveLP"
        :loading="approveLoading"
         :disabled="donatedInfo.donateLimit === donatedInfo.donatedCount || flag"
        v-if="!authLp"
        >{{ecology.grant}}</van-button
      >
      <van-button
        class="btn-div"
        :class="{'disabled': donatedInfo.donateLimit === donatedInfo.donatedCount || flag}"
        v-else
        :loading="loadingApprove"
        @click="approveDonation"
        :disabled="(donatedInfo.donateLimit === donatedInfo.donatedCount || flag)"
        >{{ ecology.now }}</van-button
      >
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import donation from '@/utils/donation'
import contractToken from '@/contracts/contractToken'
// import ChangeLanguage from '@/components/common/ChangeLanguage.vue'
// import ConnectWallet from '@/components/common/ConnectWallet.vue'
import config from '@/config/app.config.js' // 引入配置文件
export default {
  name: '',
  data () {
    return {
      donatedInfo: {},
      lightingAddress: config.donationAddress,
      eggShowTotal: false,
      shade: false,
      loadingApprove: false,
      authLp: false,
      approveLoading: false,
      lpAllowance: 0,
      flag: false
    }
  },
  computed: {
    ...mapState(['mbLang']),
    propaganda () {
      return this.$t('ecology.propaganda')
    },
    ecology () {
      return this.$t('ecology')
    }
  },
  methods: {
    init () {
      this.handledonationInfo()
      this.handleIsable()
    },
    // 路由跳转
    go (url) {
      this.$gbUtils.handleToPage(url)
    },
    // 获取授权额度
    async getAllowance () {
      // 获取授权额度
      this.approveLoading = true
      const resp = await contractToken.allowance(
        // this.lpAddress,
        this.donatedInfo.token,
        this.lightingAddress // 合约地址
      )
      console.log(resp, 'dede')
      if (resp.success) {
        this.lpAllowance = resp.result
        this.authLp = this.lpAllowance > 0
      }
      this.approveLoading = false
    },
    // 授权LP
    async approveLP () {
      this.approveLoading = true
      const resp = await contractToken.approve(
        // this.lpAddress,
        this.donatedInfo.token,
        this.lightingAddress // 合约地址
      ) // 授权LP
      this.approveLoading = false
      if (!resp.success) return this.$toast('fail')
      this.$toast('Success')
      this.authLp = true
    },
    // 参与捐赠
    async approveDonation () {
      console.log(212)
      this.loadingApprove = true
      console.log(this.donatedInfo.amount)
      const resp = await donation.donatedNow(
        this.donatedInfo.token,
        this.donatedInfo.amount
      )
      console.log(resp, 'dge')
      this.loadingApprove = false
      if (!resp.success) return this.$toast('fail')
      this.$toast('success')
      this.init()
    },
    // 复制
    handleCopy (content) {
      const text = document.createElement('textarea')
      text.value = content
      document.body.appendChild(text)
      text.select() // 选择对象
      if (document.execCommand('copy')) {
        document.execCommand('copy')
        this.$toast('copied')
      }
      // 执行浏览器复制命令
      document.body.removeChild(text)
    },
    handleToPage (link) {
      if (link) {
        window.open(link)
      } else {
        return false
      }
    },
    handleShowTotal () {
      this.eggShowTotal = !this.eggShowTotal
      this.shade = true
    },
    // 获取是否还能捐赠
    async handleIsable () {
      const resp = await donation.queryDonated()
      this.flag = resp.result
    },
    async handledonationInfo () {
      const resp = await donation.donatedInfo()
      this.donatedInfo = resp.result
      this.donatedInfo.amount = this.$web3.fromWei(this.donatedInfo.amount)
      this.donatedInfo.totalCount = this.$web3.fromWei(
        this.donatedInfo.totalCount
      )
      console.log(resp, 'de')
      this.getAllowance()
    },
    // 立即参与
    approveLp () {}
  },
  mounted () {
    this.handledonationInfo()
    this.handleIsable()
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
  padding: 20px 0;
}
.page-wrap {
  height: 276px;
  padding: 0 79px;
}
.wrap {
  box-sizing: border-box;
  color: #ffffff;
  background: #18191a;
  min-height: 100vh;
  .page-title {
    padding-top: 20px;
    font-size: 17px;
    color: #ffffff;
    text-align: center;
    .logo {
      margin-top: 60px;
      width: 222px;
      height: auto;
    }
    .marketing {
      margin-top: 30px;
      font-size: 22px;
      font-weight: 500;
      color: #ffffff;
    }
    .Genesis {
      margin-top: 17px;
      font-size: 15px;
      font-weight: 500;
      color: #ffffff;
      text-align: right;
    }
  }
}
.shade {
  width: 100%;
  height: calc(100vh - 276px);
  top: 276px;
  background: linear-gradient(180deg, #8742d3 0%, #ea5f90 58%, #f4af58 100%);
  // position: fixed;
  // top:232px;
  // left: 0;
  // bottom: 0;
  overflow: scroll;
  overflow-y: auto;
  padding: 18px 15px;
  border-radius: 20px 20px 0px 0px;
  .yinxiao-box {
    padding: 12px;
    padding-bottom: 26px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    .edu {
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);
    }
    .money {
      margin-top: 10px;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
    }
    .address {
      display: flex;
      align-content: center;
      justify-content: space-between;
      .title {
        width: 65%;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
        color: #ffffff;
        text-overflow: ellipsis;
      }
      .copy-img {
        width: 16px;
        height: 16px;
      }
    }
  }
}
.btn-div {
  width: 100%;
  height: 48px;
  background: #8742d3;
  border-radius: 48px 48px 48px 48px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  line-height: 48px;
  border: 0;
  &.disabled {
    background: rgba($color: #8742d3, $alpha: 0.6);
    color: #cdcdcd;
  }
}
</style>
